;(function ($, window, document, undefined) {

  var pluginName = 'mimNavigation';
  var defaults = {
      breakpoint: 1920,
      headerEl: '#sticky-nav',
      stickyOffset: 5
    };

  function Plugin(element, options) {
    this.element = element;
    this.options = $.extend({}, defaults, options);
    this.isExpanded = false;

    this._defaults = defaults;
    this._name = pluginName;

    this.init();
  }

  Plugin.prototype.init = function () {
    this.createSticky();

    $(this.element).on('click', '.active', this.handleExpand.bind(this));
  };

  Plugin.prototype.createSticky = function () {
    var $header = $(this.options.headerEl);
    var marginTop = parseInt($header.height());

    if (!isNaN(marginTop)) {
      this.element.setAttribute('data-margin-top', marginTop + this.options.stickyOffset);
    }

    $('.jumbotron').nextUntil('footer').wrapAll('<div data-sticky-container />');

    $('[data-sticky-container]').append($(this.element).detach());

    new Sticky('#' + this.element.getAttribute('id'));
  }

  Plugin.prototype.handleExpand = function (e) {
    var $target = $(e.toElement || e.relatedTarget || e.target);

    if (window.innerWidth > this.options.breakpoint || !$target.hasClass('active')) {
      return;
    }

    if (!this.isExpanded) {
      $(this.element).attr('data-expanded', '');
      this.isExpanded = true;
    }
    else {
      $(this.element).attr('data-expanded', null);
      this.isExpanded = false;
    }
  };

  $.fn[pluginName] = function (options) {
    return this.each(function () {
      if (!$.data(this, 'plugin_' + pluginName)) {
        $.data(this, 'plugin_' + pluginName,
          new Plugin(this, options));
      }
    });
  }

})(jQuery, window, document);