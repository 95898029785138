;(function ($, window, document, undefined) {

  /**
   * Hides the body onverflow when mobile menu opens.
  */
  $(function () {
    $('.navbar-primary .navbar-collapse').on('show.bs.collapse', function () {
      $('body')
        .addClass('overflow-hidden')
        .attr('data-mobile-menu-open', '');
    });

    $('.navbar-primary .navbar-collapse').on('hidden.bs.collapse', function () {
      $('body')
        .removeClass('overflow-hidden')
        .attr('data-mobile-menu-open', null);
    });
  });

  /**
   * Initializes sidebar navigation.
   */
  $(function () {
    $('#navbar-tertiary').mimNavigation();
  });
})(jQuery, window, document);